.btn-primary {
  &:focus-visible,
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
}

.btn {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  transition: 0.6s;
  height: 40px;

  &:hover {
    transition: 0.3s;
  }

  &.btn-danger {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: black;
    }
  }

  img {
    transition: 0.6s;
  }

  &:hover {
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;

    img {
      filter: invert(100%);
    }
  }

  &.btn-big {
    height: 80px;
    font-size: 13px;
    padding-left: 129px;
    padding-right: 129px;
    border-radius: 40px;
  }

  &:focus-visible,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

button {
  &:focus-visible,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-black {
  color: white;
  background-color: black;
  width: 100%;
  border-radius: 20px;
  transition: 0.3s;

  &:focus:active,
  &:hover {
    background-color: $primary-color;
    color: white;
    transition: 0.3s;
  }

  &:active,
  &:focus-visible,
  &:focus {
    background-color: black;
  }
}

.btn-white {
  color: black;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  transition: 0.3s;

  &:focus:active,
  &:hover,
  &.selected {
    background-color: $primary-color !important;
    color: white !important;
    transition: 0.3s;
  }

  &:active,
  &:focus-visible,
  &:focus {
    background-color: white;
    color: black;
    border-color: black;
  }
}

.btn-text {
  background-color: transparent;
  border: none;
  color: black;
  transition: 0.3s;
  display: flex;
  align-items: center;
  padding: 0;
  line-height: 1;

  img {
    height: 22px;
  }

  &.img-left {
    img {
      position: relative;
      padding-right: 5px;
      bottom: 1px;
      left: 0px;
      transition: 0.2s;
    }

    &.btn-back:hover {
      img {
        left: -2px;
      }
    }
    &.btn-upload:hover {
      img {
        bottom: 4px;
      }
    }
  }

  &.img-right {
    img {
      position: relative;
      padding-left: 5px;
      bottom: 1px;
      right: 0px;
      transition: 0.2s;
    }

    &.btn-next:hover {
      img {
        right: -2px;
      }
    }

    &.btn-upload:hover {
      img {
        bottom: 4px;
      }
    }
  }

  &:focus:active,
  &:hover {
    background-color: transparent;
    color: black;
    transition: 0.3s;
    outline: none;
    box-shadow: none;
    border: none;
    text-decoration: underline;

    img {
      filter: invert(0);
    }
  }

  &:active,
  &:focus-visible,
  &:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    color: black;
  }
}

.btn-icon {
  display: block;
  border: 0px;
  background-color: transparent;
  padding: 0px;

  &:hover {
    background-color: transparent;
    border: 0px;
  }
}
