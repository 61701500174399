.horizontal-scroll::-webkit-scrollbar {
  height: 6px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background-image: linear-gradient( 180deg, white 1%, gray 2%, gray 99%, white 100%);
border-color: white;
  border-style: solid;
  border: 2px solid white;
  // background-color: black;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 3px;
}
.horizontal-scroll{
  scrollbar-color: black;
  scrollbar-width: thin;
}