// @import "./variables.scss";

.navbar {

  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  height: $navbar-height;

  .navbar-brand {
    margin-left: 46px;
    padding-left: 0;
    height: auto;
    .navbar-brand-logo {
      width: 80px;
      height: 40px;
    }
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: space-around;
    padding-left: 48px;
    padding-right: 48px;

    .nav-link {
      color: white;
      margin-right: 23px;

      &:first-of-type {
        padding-left: 0px;
      }

      &::first-letter {
        text-transform: lowercase;
      }

      &:focus,
      &:active,
      &:hover {
        color: white;
      }

      &:hover {
        text-decoration: underline;
      }

      img {
        margin-right: 10px;
        padding-bottom: 2px;
      }
    }
  }

  button {
    border: 0px;
    background-color: transparent;
    padding-right: 0px;

    &:focus-visible,
    &:focus,
    &:active,
    &:hover {
      border: 0px;
      background-color: transparent;
      outline: none;
      box-shadow: none;

      img {
        filter: invert(0);
      }
    }
  }

  .account {
    background-color: $primary-color;
    position: absolute;
    top: 0;
    right: 0;
  }

  .nav-links {
    button {
      font-size: 13px;
    }
    .nav-language {
      color: white;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}