.modal-dialog {
  margin-top: 42vh;

  .btn-close {
    opacity: 1;
    width: 14px;
    padding: 0px;
    height: 14px;

    &:focus-visible,
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.modal-content {
  border-radius: 25px;
}

.list-modal {
  .modal-content {
    padding: 1px;

    .modal-title {
      max-width: 90%;
      font-size: 11px;
      line-height: 14px;
      text-transform: uppercase;
      padding: 1rem 0rem 0rem 1rem;
    }

    .modal-header {
      border-bottom: 0px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 21px;
      padding: 0.4rem 1rem;
      font-weight: 500;
    }
  }

  button {
    height: 40px;
    border-radius: 20px;
    font-size: 11px;
  }
}

.comment-modal {
  .modal-dialog {
    max-width: 636px;

    .modal-body {
      font-size: 14px;
      line-height: 18px;
      font-family: roboto;
    }

    .modal-header {
      max-width: 96%;
      margin-bottom: 10px;
    }
  }

  .btn-close {
    position: relative;
    top: -18px;
    left: 15px;
  }
}

.delete-modal {
  .modal-dialog {
    max-width: 328px;
  }

  .modal-content {
    padding: 20px;

    .modal-title {
      padding: 4px 0rem 0rem 1rem;
    }

    .modal-header {
      padding-bottom: 51px;
    }
  }

  button {
    &:first-of-type {
      background-color: $secondary-color;
      color: white;
      border: 1px solid $secondary-color;
      margin-bottom: 15px;

      &:hover {
        background-color: black;
        border-color: transparent;
      }
    }

    &:last-of-type {
      background-color: white;
      color: black;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

.delete-modal.list-modal button {
  &:first-of-type {
    color: white;
    background-color: black;
    border: 1px solid black;
    margin-bottom: 15px;
  }
  &:hover {
    border: 1px solid #077570;
    background-color: #077570;
  }
}

.delete-modal.delete {
  button {
    &:first-of-type {
      background-color: $secondary-color;
      color: white;
      border: 1px solid $secondary-color;
      margin-bottom: 15p
    }
    &:hover {
      border: 1px solid black;
      background-color: black;
    }
  }
}

.edit-modal {
  .modal-content {
    .modal-header {
      padding-top: 0px;
      button {
        background-color: transparent;
        border: 0px;
        padding: 0px;
    
        &:first-of-type {
          height: 29px;
          margin-left: auto;
          position: relative;
          top: -19px;
    
          img {
            width: 88%;
          }
        }
    
        &.btn-close {
          top: -19px;
          margin-left: -10px;
          width: 16px;
          height: 16px;
        }
    
        &:hover {
          background-color: transparent;
          border: 0px;
    
          img {
            filter: none;
          }
        }
      }
    }
  }

  textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    margin-bottom: 20px;
  }

  .edit-buttons {
    display: flex;
  }
}

.projector-modal {
  .modal-dialog {
    margin-top: 0px;
    width: 636px;
    height: 577px;
    top: 50%;
    transform: translateY(-50%) !important;
    max-width: inherit !important;
  }

  .modal-header {
    font-size: 11px;
    text-align: center;
    display: block;
    padding-bottom: .2rem;

    button {
      position: absolute;
      right: 20px;
      top: 20px;
      margin: 0px;
    }
  }

  .modal-body {
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    padding: 70px 0px 20px 0px;
    margin-left: 20px;
    margin-right: 20px;

    // position: relative;
    .projector-name {
      padding-top: 10px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      min-height: 43px;
    }
  }

  .modal-title {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .modal-footer {
    position: relative;

    button {
      img {
        position: relative;
        padding-left: 5px;
        bottom: 2px;
      }
    }
  }

  .modal-content {
    border-radius: 15px;
  }

  .projector-wrapper {
    &>div {
      width: 134px;
    }
  }
}

.projector-modal2 {

  .modal-dialog {
    width: 946px;
    height: 577px;
  }

  .modal-body {
    flex-direction: column;
    overflow-x: hidden;
  }

  .projector2-wrapper {
    display: flex;
    gap: 20px;

    &>div:first-of-type {
      img {
        width: 288px;
      }
    }

    .form-select {
      width: 288px;
    }
  }

  .modal-footer {
    justify-content: space-between;
  }
}

.accesories-modal {
  .modal-title {
    padding-top: 10px;
    padding-bottom: 33px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
  }

  .modal-header {
    padding: 0px;
  }

  .modal-body {
    padding: 0px;
  }

  .modal-dialog {
    min-width: 482px;
    min-height: 275px;
  }

  .modal-content {
    padding: 24px 20px 20px 20px;
  }

  .btn-primary {
    border: none !important;
    width: 100%;

    &:last-of-type {
      border: 1px solid black !important;
      margin-top: 15px;
    }

    &:focus-visible,
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
      box-shadow: none;
      background-color: black;
      color: white;
    }
  }

}