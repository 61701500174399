$primary-color: #077570;
$secondary-color: #E50000;
$tertiary-color: #BABABA;
$quaternary-color: #F2F2F2;
$quinary-color: #F8F8F8;

.quinary-bg {
  background-color: $quinary-color;
}

.primary-color {
  color: $primary-color !important;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

$navbar-height: 100px;

$configurator-left-width: 30%;
$configurator-left-max-width: 630px;
$configurator-left-min-width: 470px;