
@font-face {
  font-family: "roboto";
  src: url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/l?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "roboto";
  src: url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/l?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/d?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "roboto";
  src: url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/l?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/d?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=66b6f45d0ef17dc090abd1a7c3c0c73ff39663a6235120922f51f9866883c799&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "termina";
  src: url("https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/l?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/a?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "termina";
  src: url("https://use.typekit.net/af/3e72b6/00000000000000007735b21e/30/l?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/3e72b6/00000000000000007735b21e/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/3e72b6/00000000000000007735b21e/30/a?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "termina";
  src: url("https://use.typekit.net/af/574117/00000000000000007735b222/30/l?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/574117/00000000000000007735b222/30/d?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/574117/00000000000000007735b222/30/a?primer=f1a820c30740dc6492ec51f34d8da727435cd9e66a2845f8f7f2c58da176b1fd&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

.tk-roboto {
  font-family: "roboto", sans-serif;
}

.tk-termina {
  font-family: "termina", sans-serif;
}
