$primary: #077570;
$secondary: #E50000;
$tertiary-color: #9b9b9b;
$gray: #474747;
$black: black;

$font-size-base: 0.8rem;
$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$headings-font-weight: 400;
$h4-font-weight: 300;
$headings-color: $black;

$btn-variant-primary-border: 1px solid white;

$btn-font-size: 11px;
$btn-box-shadow: unset;
$btn-focus-box-shadow: unset;
$btn-focus-outline: unset;
$btn-primary-focus-box-shadow: unset;
$btn-active-box-shadow: unset;
$btn-border-radius: 20px;

$btn-box-shadow:              inset 0 1px 0 rgba(white, .15), 0 1px 1px rgba(white, .075);
// $btn-focus-width:             $input-btn-focus-width;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 0 3px 5px rgba(white, .125);

$btn-link-color: $black;
$btn-link-hover-color: $black;

$btn-padding-x: 20px;
$btn-info-padding-x: 0px;
$btn-padding-y: 6px;



$link-color: $gray;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: underline;

$table-color: $gray;
$table-bg: #f0f0f0;
$table-hover-bg: #5d938f;
$table-hover-color: #ffffff;
$table-border-color: $secondary;

$body-bg: #F8F8F8;

$grid-gutter-width: 20px;

$input-bg: #ffffff;
$input-border-color: $secondary;

$card-border-width: 0px;
$card-spacer-y: 15px;
$card-spacer-x: 20px;

$modal-header-border-width: 0px;
$modal-footer-border-width: 0px;

$badge-font-weight: 400;

$form-check-input-width: 1.4em;
$form-check-input-border: 2px solid #757575;

$tooltip-bg: $gray;
$tooltip-color: #ffffff;

$form-select-focus-border-color:  $black;
$form-select-border-color:  $black;
$form-select-focus-box-shadow:    none;
$form-select-font-size: 12px;
$form-select-border-radius: 0px;

$form-range-track-bg: $black;
$form-range-thumb-bg: $black;
$form-range-track-height: 0.08rem;
$form-range-thumb-focus-box-shadow: none;
$form-range-thumb-active-bg: none;